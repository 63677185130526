import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAvailableModuleServer,
  IModuleServer,
  ManageModuleTablesTypes,
} from 'interfaces/modules/module.interface';
import {
  fetchAvailableModules,
  fetchAvailableModulesWithoutLoading,
} from 'api/manageModules/manageModulesThunks';
import { isObjectsAreEqualDeep } from 'utils/commonFunctions/CommonFunctions';
interface IManageModulesState {
  selectedModule: IModuleServer | null;
  selectedModuleInTenantTable: IModuleServer | null;
  availableModules: IAvailableModuleServer | {};
  loading: boolean;
}

export const SLICE_KEY = 'manageModules';

const initialState: IManageModulesState = {
  selectedModule: null,
  selectedModuleInTenantTable: null,
  availableModules: [],
  loading: false,
};

const sortByTenantAndRemoveInvalidModules = (
  payload: IAvailableModuleServer
): IAvailableModuleServer => {
  const updatedPayload = Object.entries(payload).map(([key, modules]) => {
    const validModules = modules.filter(
      (module) => module.id !== null && module.name && module.name.trim() !== ''
    );
    return [key, validModules];
  });

  updatedPayload.sort(([a], [b]) => (a as string).localeCompare(b as string));

  return Object.fromEntries(updatedPayload);
};

const managaModulesSlice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setSelectedModule: (
      state,
      {
        payload,
      }: PayloadAction<{
        table: ManageModuleTablesTypes;
        module: IModuleServer | null;
      }>
    ) => {
      if (payload.table === 'module-list') {
        return {
          ...state,
          selectedModule: payload.module,
        };
      }
      return {
        ...state,
        selectedModuleInTenantTable: payload.module,
      };
    },
    clearState: (state) => {
      return {
        selectedModule: null,
        selectedModuleInTenantTable: null,
        availableModules: [],
        loading: false,
      };
    },
    setSelectedModuleFromRow: (
      state,
      {
        payload,
      }: PayloadAction<{
        table: ManageModuleTablesTypes;
        id: number;
        slave: string;
      } | null>
    ) => {
      let selectedModule = null;
      if (payload) {
        const moduleArray: IModuleServer[] = (
          state.availableModules as IAvailableModuleServer
        )[payload.slave]
          ? Array.from(
              (
                state.availableModules as {
                  [key: string]: IModuleServer[];
                }
              )[payload.slave]
            )
          : [];
        const foundModule = moduleArray.find((x) => x.id === payload.id);
        if (foundModule) {
          selectedModule = { ...foundModule, slave: payload.slave };
        }
        if (payload?.table === 'module-list') {
          return {
            ...state,
            selectedModule: foundModule ?? null,
          };
        }
        return {
          ...state,
          selectedModuleInTenantTable: foundModule ?? null,
        };
      }
      return {
        ...state,
        selectedModule,
        selectedModuleInTenantTable: selectedModule,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAvailableModules.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(fetchAvailableModules.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(fetchAvailableModules.fulfilled, (state, { payload }) => {
      const sortedPayload = sortByTenantAndRemoveInvalidModules(payload);
      return {
        ...state,
        availableModules: sortedPayload,
        loading: false,
      };
    });
    builder.addCase(
      fetchAvailableModulesWithoutLoading.fulfilled,
      (state, { payload }) => {
        const sortedPayload = sortByTenantAndRemoveInvalidModules(payload);
        let updatedSelectedModule: IModuleServer | null = null;
        let isSelectedModuleChanged = false;
        if (state.selectedModule !== null) {
          updatedSelectedModule =
            sortedPayload[state.selectedModule?.slave]?.find(
              (x) => x.id === state.selectedModule?.id
            ) ?? null;
          isSelectedModuleChanged = !isObjectsAreEqualDeep(
            state.selectedModule,
            updatedSelectedModule
          );
        }
        return {
          ...state,
          availableModules: sortedPayload,
          selectedModule: isSelectedModuleChanged
            ? updatedSelectedModule
            : state.selectedModule,
          loading: false,
        };
      }
    );
    builder.addCase(
      fetchAvailableModulesWithoutLoading.rejected,
      (state, action) => {
        return {
          ...state,
          loading: false,
        };
      }
    );
  },
});
export const selectSelectedModule = (state: any) =>
  state.manageModules.selectedModule;

export const selectSelectedModuleInTenantTable = (state: any) =>
  state.manageModules.selectedModuleInTenantTable;

export const selectAvailableModules = (state: any) =>
  state.manageModules.availableModules;

export const selectModulesLoading = (state: any) => state.manageModules.loading;

export const { setSelectedModule, setSelectedModuleFromRow, clearState } =
  managaModulesSlice.actions;

export default managaModulesSlice.reducer;
