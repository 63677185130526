import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { useRef } from 'react';
import { MANAGE_MODULES_BUTTONS } from 'interfaces/modals/manageModuleButtonsModal.interface';

export interface IManageModuleButtonsModal {
  actionType: MANAGE_MODULES_BUTTONS;
}

const actionTexts = {
  [MANAGE_MODULES_BUTTONS.stop]: 'Are you sure you want to stop this module?',
  [MANAGE_MODULES_BUTTONS.restart]:
    'Are you sure you want to restart this module?',
  [MANAGE_MODULES_BUTTONS.remove]:
    'Are you sure you want to remove this module?',
};

export const ManageModuleButtonsModal = ({
  actionType,
}: IManageModuleButtonsModal) => {
  return (
    <CsbErrorBoundary>
      <div data-testid="ManageModuleButtonsModal">
        <span>{actionTexts[actionType]}</span>
      </div>
    </CsbErrorBoundary>
  );
};
