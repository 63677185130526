import axios from 'axios';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';

const UNEXPECTED_ERROR_MESSAGE = 'An unexpected error occurred';

export const handleThunkError = (
  error: any,
  rejectWithValue: (value: unknown) => any
) => {
  if (axios.isAxiosError(error)) {
    const errorMessage = consoleErrorMessage(error);
    return rejectWithValue(errorMessage ?? error);
  }
  consoleErrorMessage(UNEXPECTED_ERROR_MESSAGE);
  return rejectWithValue(UNEXPECTED_ERROR_MESSAGE);
};
