import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import styles from './DeployModuleModal.module.scss';
import { useEffect, useState } from 'react';
import {
  IDeployModuleSearchRow,
  IDeployModuleServerRow,
} from 'interfaces/modules/module.interface';
import SelectServersSection from './components/SelectServersSection/SelectServersSection';
import SelectModuleSection from './components/SelectModuleSection/SelectModuleSection';
import SearchResultsSection from './components/SearchResultsSection/SearchResultsSection';
import {
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useAppSelector } from 'redux/hooks';
import TableOverlayComponent from 'components/TableOverlayComponent/TableOverlayComponent';
import { PAGE_OPTIONS } from 'interfaces/table.interface';

interface IDeployModuleModal {
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

export default function DeployModuleModal({
  register,
  watch,
  setValue,
}: IDeployModuleModal) {
  const [enableIndex, setEnableIndex] = useState(0);
  const modalInfo = useAppSelector((state) => state.modal);
  const [isLoading, setIsLoading] = useState(false);
  const [modules, setModules] = useState<IDeployModuleSearchRow[] | undefined>(
    undefined
  );
  const [servers, setServers] = useState<IDeployModuleServerRow[] | undefined>(
    undefined
  );

  useEffect(() => {
    if (modalInfo?.additionalData?.isLoading) {
      setIsLoading(modalInfo?.additionalData?.isLoading);
    }
  }, [modalInfo]);

  return (
    <CsbErrorBoundary>
      <div
        className={`${styles['deploy-module-modal-container']}`}
        data-testid="DeployModuleModal"
      >
        {isLoading && (
          <TableOverlayComponent
            page={PAGE_OPTIONS.commonLoader}
            style="fixed"
            border={true}
          />
        )}
        <div className={isLoading ? styles['disabled-section'] : ''}>
          <SelectModuleSection
            register={register}
            watch={watch}
            setValue={setValue}
            setEnableIndex={setEnableIndex}
            setServers={setServers}
            setModules={setModules}
            setIsLoading={setIsLoading}
          />
        </div>
        <div
          className={
            enableIndex < 1 || isLoading ? styles['disabled-section'] : ''
          }
        >
          <SearchResultsSection modules={modules} />
        </div>
        <div
          className={
            enableIndex < 2 || isLoading ? styles['disabled-section'] : ''
          }
        >
          <SelectServersSection servers={servers} />
        </div>
      </div>
    </CsbErrorBoundary>
  );
}
