import { Iconsvg, Tooltip, TooltipBody } from '@wk/components-react16';
import style from './SourceFromBuIcon.module.scss';
import { Useuid } from 'utils/hooks/useUid';

export interface ISouceFromBuIcon {
  isSourcedFromBu: boolean | undefined;
  id: string;
  size?: number;
  type?: 'top' | 'right' | null;
}
export const SouceFromBuIcon = ({
  isSourcedFromBu,
  id,
  size,
  type,
}: ISouceFromBuIcon) => {
  const uniquieId = Useuid() + id;
  return isSourcedFromBu ? (
    <>
      <div
        className={
          style[`${type === 'top' ? 'top-positioned' : 'right-positioned'}`]
        }
        id={`sourceFromBuIcon-${uniquieId}`}
        data-testid="SouceFromBuIcon"
      >
        <Iconsvg name="info" size={size ?? 18} color="black"></Iconsvg>
      </div>
      <Tooltip
        position="left"
        targetSelector={`#sourceFromBuIcon-${uniquieId}`}
        id={`sourceFromBuIcontooltip-${uniquieId}`}
      >
        <TooltipBody slot="tooltipBody">
          <span>Value was sourced from BUAdmin</span>
        </TooltipBody>
      </Tooltip>
    </>
  ) : (
    <></>
  );
};
