import LogModal from 'components/Modals/body/LogModal/LogModal';
import ServiceNowModal from 'components/Modals/body/ServiceNowModal/ServiceNowModal';
import ConfigureAndRunModal from 'components/Modals/body/ConfigureAndRunModal/ConfigureAndRunModal';
import CreateProductModal from 'components/Modals/body/CreateProductModal/CreateProductModal';
import DeployModuleModal from 'components/Modals/body/DeployModuleModal/DeployModuleModal';

import CancelResetApplyFooter from 'components/Modals/footers/CancelResetApplyFooter/CancelResetApplyFooter';
import CancelDownloadFooter from 'components/Modals/footers/CancelDownloadFooter/CancelDownloadFooter';
import CancelSubmitFooter from 'components/Modals/footers/CancelSubmitFooter/CancelSubmitFooter';
import CancelNextSubmitFooter from 'components/Modals/footers/CancelNextSubmit/CancelNextSubmitFooter';
import CancelDeployFooter from 'components/Modals/footers/CancelDeployFooter/CancelDeployFooter';
import { ManageModuleButtonsModal } from './body/ManageModuleButtonsModal/ManageModuleButtonsModal';
import { ManageModuleButtonsFooter } from './footers/ManageModuleButtonsFooter/ManageModuleButtonsFooter';

export enum MODAL_NAMES {
  LOG_MODAL = 'modals/logModal',
  SERVICE_NOW_MODAL = 'modals/serviceNowModal',
  CONFIGURE_AND_RUN = 'modals/configureAndRun',
  CREATE_PRODUCT = 'modals/createProductModal',
  EMPTY = '',
  EDIT_PRODUCT = 'modals/editProductModal',
  DEPLOY_MODULE = 'modals/deployModuleModal',
  MANAGE_MODULE_BUTTONS = 'modals/manageModuleButtonsModal',
}

export const MODAL_FORMS_LIST: {
  [key in MODAL_NAMES]?: any;
} = {
  [MODAL_NAMES.LOG_MODAL]: LogModal,
  [MODAL_NAMES.SERVICE_NOW_MODAL]: ServiceNowModal,
  [MODAL_NAMES.CONFIGURE_AND_RUN]: ConfigureAndRunModal,
  [MODAL_NAMES.CREATE_PRODUCT]: CreateProductModal,
  [MODAL_NAMES.EDIT_PRODUCT]: CreateProductModal,
  [MODAL_NAMES.DEPLOY_MODULE]: DeployModuleModal,
  [MODAL_NAMES.MANAGE_MODULE_BUTTONS]: ManageModuleButtonsModal,
};

export const MODAL_FOOTER_LIST: { [key: string]: any } = {
  'cancel-reset-apply': CancelResetApplyFooter,
  'cancel-download': CancelDownloadFooter,
  'cancel-submit': CancelSubmitFooter,
  'cancel-next': CancelNextSubmitFooter,
  'cancel-deploy': CancelDeployFooter,
  'manage-module-buttons': ManageModuleButtonsFooter,
};
