import { InputField, SelectField } from '@wk/components-react16';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import {
  IWorkflow,
  IWorkflowProduct,
} from 'interfaces/runOrchestration/workflowRow';
import { useEffect, useState } from 'react';
import style from './SetProductParametersStep.module.scss';
import { UseFormRegister } from 'react-hook-form';
import { AddZeroPrefix } from 'utils/commonFunctions/CommonFunctions';
import { SouceFromBuIcon } from 'components/CustomIcons/SourceFromBuIcon/SourceFromBuIcon';
import {
  IOrchestrationFullStep,
  IParamGroups,
  parameterTypes,
} from 'interfaces/orchestration.interface';

export interface ISetProductParametersStepProps {
  selectedOrchestration: IWorkflow | IWorkflowProduct | null;
  register: UseFormRegister<any>;
}

const SetProductParametersStep = ({
  selectedOrchestration,
  register,
}: ISetProductParametersStepProps) => {
  const [parameters, setParameters] = useState<IParams[]>([]);
  const [openIndex, setOpenIndex] = useState<number>(0);

  useEffect(() => {
    if (selectedOrchestration && selectedOrchestration.steps) {
      const parameterArray = checkForAllParameters(
        selectedOrchestration?.steps
      );
      setParameters(parameterArray);
      setOpenIndex(parameterArray?.[0]?.index);
    }
  }, [selectedOrchestration]);

  const handleButtonClick = (index: any) => {
    setOpenIndex(index);
  };

  return (
    <CsbErrorBoundary>
      <div
        data-testid="SetProductParametersStep"
        className={style['params-container']}
      >
        <section className={style['params-list']}>
          <span className={style['params-list-title']}>
            Define step parameters
          </span>
          {parameters.map((param: IParams, index: number) => (
            <button
              onClick={() => handleButtonClick(param.index)}
              key={param.index}
              data-testid={`ParamListButton-${index}`}
              className={
                style[
                  `${
                    param.index === openIndex
                      ? 'params-list-open-btn'
                      : 'params-list-closed-btn'
                  }`
                ]
              }
            >
              <h2 id="expandedByDefault">{`${AddZeroPrefix(param.index)} ${
                param.name
              }`}</h2>
            </button>
          ))}
        </section>
        <div className={style['params-divider']}></div>
        <section className={style['params-values']}>
          {parameters?.length > 0 && (
            <span className={style['step-title']}>{`${AddZeroPrefix(
              parameters.find((p) => p.index === openIndex)?.index ?? 0
            )} ${parameters.find((p) => p.index === openIndex)?.name}`}</span>
          )}
          {parameters.map((param: IParams, index: number) => (
            <div
              style={{ display: openIndex === param.index ? 'block' : 'none' }}
              key={param.index}
              data-testid={`ParamsValuesSection-${index}`}
            >
              {param.attributes.map((attribute) => (
                <div key={attribute?.paramId}>
                  <ParamField attribute={attribute} register={register} />
                </div>
              ))}
            </div>
          ))}
        </section>
      </div>
    </CsbErrorBoundary>
  );
};

const ParamField = ({
  attribute,
  register,
}: {
  attribute: IParams['attributes'][number];
  register: UseFormRegister<any>;
}) => {
  const haveMultipleValues = attribute.valueAsArray?.length;
  const isBoolean = attribute.type === 'BOOLEAN';
  return (
    <CsbErrorBoundary>
      <div
        data-testid="ParamField"
        className={style['param-field']}
        key={attribute?.paramId}
      >
        {haveMultipleValues ? (
          <SelectField label={attribute?.name} labelFor={attribute?.paramId}>
            <div>
              <SouceFromBuIcon
                isSourcedFromBu={attribute.configuredExternally}
                id={attribute?.paramId}
                type={'top'}
              />
              <select
                id={attribute?.paramId}
                data-testid={`Select-${attribute?.paramId}`}
                {...register(`params.${attribute.textId}-${attribute.paramId}`)}
                defaultValue={''}
              >
                <option value="" disabled hidden selected>
                  {attribute.value}
                </option>
                {attribute.valueAsArray.map((value: any) => (
                  <option key={value}>{value}</option>
                ))}
              </select>
            </div>
          </SelectField>
        ) : isBoolean ? (
          <SelectField label={attribute?.name} labelFor={attribute?.paramId}>
            <div>
              <SouceFromBuIcon
                isSourcedFromBu={attribute.configuredExternally}
                id={attribute?.paramId}
                type={'top'}
              />
              <select
                id={attribute?.paramId}
                data-testid={`Select-${attribute?.paramId}`}
                {...register(`params.${attribute.textId}-${attribute.paramId}`)}
                defaultValue={''}
              >
                <option value="" disabled hidden selected>
                  {attribute.value}
                </option>
                <option key={'true'}>{'true'}</option>
                <option key={'false'}>{'false'}</option>
              </select>
            </div>
          </SelectField>
        ) : (
          <InputField label={attribute.name} labelFor={attribute.paramId}>
            <div>
              <SouceFromBuIcon
                isSourcedFromBu={attribute.configuredExternally}
                id={attribute?.paramId}
                type={'top'}
              />
              <input
                type="text"
                id={attribute?.paramId}
                placeholder={attribute.value}
                data-testid={`Input-${attribute?.paramId}`}
                {...register(`params.${attribute.textId}-${attribute.paramId}`)}
              />
            </div>
          </InputField>
        )}
      </div>
    </CsbErrorBoundary>
  );
};

interface IParams {
  name: string;
  index: number;
  attributes: {
    name: string;
    textId: string;
    value: string;
    type: parameterTypes;
    paramId: string;
    valueAsArray: string[] | number[];
    configuredExternally: boolean;
  }[];
}

const checkForAllParameters = (steps: IOrchestrationFullStep[]): IParams[] => {
  const parameterArray: IParams[] = [];
  steps?.forEach((step: IOrchestrationFullStep) => {
    if (step.paramGroups && step.paramGroups.length > 0) {
      const newParam = {
        name: step.stepTitle,
        index: Number(step.stepIndex),
        attributes: [] as any[],
      };
      // paramGroup only has 1 position
      step.paramGroups?.forEach((group: IParamGroups) => {
        group.parameters?.forEach((param) => {
          newParam.attributes.push({
            name: param.parameter?.name,
            textId: param.parameter.textId,
            value: param.value,
            type: param.parameter?.type,
            paramId: param.paramId?.toString(),
            valueAsArray: param.parameter?.valueAsArray,
            configuredExternally: param.configuredExternally,
          });
        });
      });
      parameterArray.push(newParam);
    }
  });
  return parameterArray;
};

export default SetProductParametersStep;
