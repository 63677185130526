import { ILogFromServer, ILogRow } from 'interfaces/logRow.interface';
import { IStepInformation } from 'interfaces/orchestration.interface';
import { DateUtils } from 'utils/dateUtils/DateUtils';

export const objectFromLogData = (
  log: ILogFromServer[],
  steps?: IStepInformation[]
): ILogRow[] => {
  return log.map((item: ILogFromServer) => {
    return {
      createdTime: DateUtils.formatDateFromUtcString(item.timestamp) ?? '-',
      severity: item.logLevel ?? 'INFO',
      ['Step name']: item.metadata?.CSBWorkflowStepName
        ? `${getStepIndex(steps, item.metadata.CSBJobStepId)}  ${
            item.metadata?.CSBWorkflowStepName
          }`
        : 'No Step name available',
      message: item.message ?? '-',
    } as ILogRow;
  });
};

const getStepIndex = (
  steps?: IStepInformation[],
  jobStepId?: string
): string => {
  const firstStepIndex = (steps?.[0]?.workflowStepId ?? 0) + 1;
  const currentStep = steps?.find(
    (step) => step.jobStepId === Number(jobStepId)
  );
  if (currentStep) {
    const currentStepIndex = currentStep.workflowStepId;
    const stepDifference = firstStepIndex - currentStepIndex;
    return stepDifference.toString();
  }
  return '';
};
