import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const modulesApi = createApi({
  reducerPath: 'modulesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/csb/modules',
    prepareHeaders: (headers) => {
      return headers;
    },
  }),
  endpoints: (builder) => ({}),
});

export const {} = modulesApi;
